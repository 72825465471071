@import "./app/brand-variables";

$header-color: #fff;

body {
  background-color: #f5f8fa;
}

.navbar {
  border-radius: 0;
}

.navbar-default {
  background-color: $header-color;
  font-family: $dashboard-font;
}

.panel-default {
  > .panel-heading {
	background-color: $header-color;
	font-family: $dashboard-font;
  }
}

h3 {
  font-family: $dashboard-font;
}